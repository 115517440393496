import { combineReducers } from "redux";

const showSplashScreenReducer = (showSplashScreen = true, action) => {
  if (action.type === "SET_SHOW_SPLASHSCREEN") {
    return action.payload;
  }
  return showSplashScreen;
};

export default combineReducers({
  showSplashScreen: showSplashScreenReducer,
});
